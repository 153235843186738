<!-- 德育管理/德育档案/德育统计 -->
<template>
    <s-manager-edu-moral-statistics-scroll-wrapper />
</template>
<script>
import SManagerEduMoralStatisticsScrollWrapper from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper";

export default {
    name: "SManagerEduMoralStatistics",
    // SManagerEduMoralStatistics
    // SManagerEduMoralStatisticsScrollWrapper
    components: {
        SManagerEduMoralStatisticsScrollWrapper,
    },
};
</script>


