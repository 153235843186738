<!-- 校园OA/教师考勤 -->
<template>
    <div>
        教师考勤
    </div>
</template>
<script>
export default {
    name: 'SManagerCheckTeacher',
}
</script>