<!--
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2023-08-29 09:12:32
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2023-08-29 09:14:11
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SManagerDocument.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <KeepAliveRouterView />
</template>
<script>
    export default{
        name:'SManagerDocument'
    }
</script>
