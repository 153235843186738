<!-- 德育管理/班级管理/班级量化 -->
<template>
    <s-manager-edu-moral-class-data-scroll-wrapper />
</template>
<script>
import SManagerEduMoralClassDataScrollWrapper from "@/components/scrollWrapper/SManagerEduMoralClassData";

export default {
    name: "SManagerEduMoralClassData",
    components: {
        SManagerEduMoralClassDataScrollWrapper,
    },
};
</script>
