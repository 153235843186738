<!-- 德育管理/德育档案/德育报表 -->
<template>
    <div class="content-bg">
        <!-- <div class="tab-switch">
            <span @click="type=1" :class="type===1?'item-ok':''">德育报表</span>
            <el-button type="text" v-has-permi="['moralmanage:moralreport:room']" @click="type=2" :class="type===2?'item-oks':'not-ok'">宿舍报表</el-button>
        </div> -->
        <tabbed-page
            :tabList="tabList"
            @changeTabs="changeTabs"
        ></tabbed-page>
        <s-manager-edu-moral-report-scroll-wrapper v-if="type===1" />
        <s-manager-edu-dormitory-report-scroll-wrapper v-else />
    </div>
</template>

<script>
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import SManagerEduMoralReportScrollWrapper from "@/components/scrollWrapper/SManagerEduMoralReport";
import SManagerEduDormitoryReportScrollWrapper from "@/components/scrollWrapper/SManagerEduDormitoryReport";

export default {
    name: "SManagerEduMoralReport",
    components: {
        TabbedPage,
        SManagerEduMoralReportScrollWrapper,
        SManagerEduDormitoryReportScrollWrapper
    },
    created() {
        if (this.$store.state.permissions.includes('moralmanage:moralreport:room')) {
            this.tabList.push({
                id: 2,
                title: '宿舍报表',
                sel: false
            })
        }
        this.type = 1;
    },
    activated(){
        this.type = 1;
        this.changeTabs(this.tabList[0]);
    },
    data() {
        return {
            tabList: [
                {
                    id: 1,
                    title: '德育报表',
                    // vHasPermi: ['moralmanage:moralreport'],
                    sel: true
                },
                // {
                //     id: 2,
                //     title: '宿舍报表',
                //     vHasPermi: ['moralmanage:moralreport:room'],
                //     sel: false
                // }
            ],
            type: 1,
            isShowTab:true
        }
    },
    methods: {
        changeTabs (data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.type = data.id;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
    }
};
</script>

<style lang="scss" scoped>
.tab-switch {
    margin-bottom: 10px;
    margin-top: 1px;
    margin-right: 10px;
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #FFFFFF;

    span {
        cursor: pointer;
        margin: 0 20px;
        font-size: 14px;
        line-height: 50px;
    }

    .item-ok {
        color: #3064BF;
        line-height: 48px;
        border-bottom: solid 2px #3064BF;
    }
    .item-oks {
        margin: 0 20px;
        width: 58px;
        height: 50px;
        color: #3064BF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 2px #3064BF;
    }
    .not-ok {
        margin: 0 20px;
        width: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        height: 50px;
        color: #2b2f33;
    }
}
</style>
