<template>
    <!-- 智慧管理 > 德育管理 > 德育档案 > 德育考核-->
    <div>
        <tabbed-page
            v-if="isShowTab"
            :tabList="tabList"
            @changeTabs="changeTabs"
        >
        </tabbed-page>
        <component
            :is="comps[idx]"
            @transfer="showTab"
            @setPageInitTabs = "setPageInitTabs"
        >
		</component>
    </div>
</template>

<script>
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
import SManagerEduMoralAssessmentScrollWrapper from "@/components/scrollWrapper/SManagerEduMoralAssessment";
// import SManagerEduDormitoryAssessmentScrollWrapper from "@/components/scrollWrapper/SManagerEduDormitoryAssessment";
export default {
    name: "SManagerEduMoralAssessmentView",
    components: {
        TabbedPage,
        SManagerEduMoralAssessmentScrollWrapper,
        SManagerEduClassAssessmentScrollWrapper: () => import(/* webpackChunkName:'SManagerEduClassAssessmentScrollWrapper' */"@/components/scrollWrapper/SManagerEduClassAssessment"),
        SManagerEduDormitoryAssessmentScrollWrapper: () => import(/* webpackChunkName:'SManagerEduDormitoryAssessmentScrollWrapper' */"@/components/scrollWrapper/SManagerEduDormitoryAssessment"),
    },
    data() {
        return {
            type: 1,
            isShowTab:true,
            idx: 0,
            tabList: [
                {
                    id: 0,
                    title: '德育考核',
                    com: 'SManagerEduMoralAssessmentScrollWrapper',
                    sel: true,
                },
                {
                    id: 1,
                    title: '班级考核',
                    com: 'SManagerEduClassAssessmentScrollWrapper',
                    sel: false,
                    vHasPermi: ['moralmanage:moralassessment:class'],
                },
                {
                    id: 2,
                    title: '宿舍考核',
                    com: 'SManagerEduDormitoryAssessmentScrollWrapper',
                    sel: false,
                    vHasPermi: ['moralmanage:moralassessment:room'],
                }
            ],
            comps: [
                'SManagerEduMoralAssessmentScrollWrapper',
                'SManagerEduClassAssessmentScrollWrapper',
                'SManagerEduDormitoryAssessmentScrollWrapper'
            ]
        }
    },
    created(){

        this.isShowTab = true;
        this.init();
    },
    activated(){
        this.isShowTab = true;
        this.init();
    },
    methods:{
        init () {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map(item => item.com);
            console.log(this.comps, this.tabList, 'this.tabList')
        },
        changeTabs (data) {
			this.tabList.forEach((item, index) => {
				if (item.id === data.id) {
					this.$set(item, 'sel', true)
					this.idx = index;
				} else {
					this.$set(item, 'sel', false)
				}
			})
		},
        showTab(msg){
            this.isShowTab = msg
        },
        setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0
            this.isShowTab = true
        }
   }
}
</script>

<style lang="scss" scoped>
.tab-switch {
    margin-bottom: 10px;
    margin-top: 1px;
    margin-right: 10px;
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #FFFFFF;

    span {
        cursor: pointer;
        margin: 0 20px;
        font-size: 14px;
        line-height: 50px;
    }

    .item-ok {
        color: #3064BF;
        line-height: 48px;
        border-bottom: solid 2px #3064BF;
    }
    .item-oks {
        margin: 0 20px;
        width: 58px;
        height: 50px;
        color: #3064BF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 2px #3064BF;
    }
    .not-ok {
        margin: 0 20px;
        width: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        height: 50px;
        color: #2b2f33;
    }
}
</style>
