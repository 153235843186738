<!-- 德育管理/班级管理/学生量化 -->
<template>
    <s-manager-edu-moral-stu-data-scroll-wrapper />
</template>
<script>
import SManagerEduMoralStuDataScrollWrapper from "@/components/scrollWrapper/SManagerEduMoralStuData";

export default {
    name: "SManagerEduMoralStuData",
    components: {
        SManagerEduMoralStuDataScrollWrapper,
    },
};
</script>
