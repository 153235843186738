<!-- 智慧管理/基础数据管理/教室管理 -->
<template>
    <s-manager-base-classroom-scroll-wrapper></s-manager-base-classroom-scroll-wrapper>
</template>

<script>
import SManagerBaseClassroomScrollWrapper from "@/components/scrollWrapper/SManagerBaseClassroomScrollWrapper.vue";
export default {
    name: "SManagerBaseClassroom",
    components: {
        SManagerBaseClassroomScrollWrapper
    }
}
</script>

<style scoped>

</style>