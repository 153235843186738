<template>
	<!-- 校园OA > 教师考勤 -->
    <s-manage-teacher-attendance-record-scroll-wrapper></s-manage-teacher-attendance-record-scroll-wrapper>
</template>
<script>
import SManageTeacherAttendanceRecordScrollWrapper from '@/components/scrollWrapper/SManageTeacherAttendanceRecord.vue';
export default {
    name: "SManageTeacherAttendanceRecord",
    components: {
        SManageTeacherAttendanceRecordScrollWrapper
    }
}
</script>