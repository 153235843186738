<!--
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2023-08-30 10:20:28
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2023-09-04 11:10:49
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SManagerAllDocument.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <component @change="change" :is="curCom"  :mode="mode" :id="id"></component>
    </div>
</template>

<script>

export default {
    components: {
        SManagerAllDocumentList: () => import(/* webpackChunkName:'SManagerMyReceiveList' */"@/components/scrollWrapper/SManagerAllDocumentList"),
		SManagerAllDocumentFeedback: () => import(/* webpackChunkName:'SManagerMyReceiveFeedback' */"@/components/scrollWrapper/SManagerAllDocumentFeedback"),
    },
    data() {
        return {
            // list展示列表页面；feedback展示反馈页
            mode: "list",
            show: true,
            curCom:'SManagerAllDocumentList',
            id:''
        };
    },
    created() {
    },
    mounted() {
        this.change(this.mode)
    },
    methods: {
        change(mode, id) {
            console.log('-----id -----',id)
            this.mode = mode;
            this.id = id
            if(this.mode === 'list'){
                this.curCom = 'SManagerAllDocumentList'
                this.setGlobalPageType('list')
            }else{
                this.curCom = 'SManagerAllDocumentFeedback'
                this.setGlobalPageType('detail')
            }
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
