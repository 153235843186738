<!-- 德育督导 -->
<template>
    <div>
        <SManagerEduMoralSupervisionData></SManagerEduMoralSupervisionData>
        <!-- <component @change="change" :is="curCom" :mode="mode" :id="id"></component> -->
    </div>
</template>

<script>

export default {
    components: {
        SManagerEduMoralSupervisionData: () => import(/* webpackChunkName:'SManagerEduMoralSupervisionData' */"@/components/scrollWrapper/SManagerEduMoralSupervisionData"),
    },
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style lang="scss" scoped>
</style>
