<template>
    <!-- 智慧管理 > 德育管理 > 德育档案-->
    <keep-alive-router-view></keep-alive-router-view>
</template>

<script>
export default {
    name: "SManagerEduMoralDocument"
}
</script>

<style scoped>

</style>