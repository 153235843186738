<!-- 德育管理/班级管理/年级量化 -->
<template>
    <s-manager-edu-moral-grade-data-scroll-wrapper></s-manager-edu-moral-grade-data-scroll-wrapper>
</template>

<script>
import SManagerEduMoralGradeDataScrollWrapper from '@/components/scrollWrapper/SManagerEduMoralGradeDataScrollWrapper.vue'
export default {
    name: 'SManagerEduMoralGradeData',
    components: {
        SManagerEduMoralGradeDataScrollWrapper
    }
}
</script>

<style>

</style>